.App {
  font-family: sans-serif;
  text-align: center;
}
.main {
  padding: 0;
}
.headerWarn {
  background-color: #000;
  color: #FFF;
  text-align: center;
  padding: 1px 40px;
  font-size: 14px;
}
.header-logo, .header-filtro {
  padding: 10px 40px;  
}
.header-logo h1, .header-logo p {
  margin-left: 100px;
}
.header-logo h1 {
    font-size: 34px;
}
.header-filtro {
  background-color: #1E72BC;
}
.produtos {
  padding: 0 40px;
}



.list-inline-item {
  display:inline-block
}
.list-inline-item:not(:last-child) {
margin-right:.5rem
}
.category-stripe a .icon-background {
  background-color:#fff;
  border-radius:100%;
  width:58px;
  height:58px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  box-shadow:0 1px 1px 0 rgba(0,0,0,.1);
  transition:box-shadow .3s
 }
 .category-stripe a .icon-background:hover {
  box-shadow:0 2px 4px 0 rgba(0,0,0,.19),0 2px 4px 0 rgba(0,0,0,.19)
 }
 .category-stripe a small {
  text-align:center;
  margin-top:8px;
  color: #FFF;
 }
 .category-stripe {
  overflow-y:hidden;
  overflow-x:scroll;
  -webkit-overflow-scrolling:touch;
  display:flex;
  flex-direction:row;
  align-items:flex-start
 }
 @media(min-width:992px) {
  .category-stripe {
   justify-content:center;
   overflow-x:hidden
  }
 }
 @media(min-width:992px)and (max-width:1200px) {
  .category-stripe li:nth-last-child(-n+3):not(.all-categories) {
   display:none
  }
 }
 .category-stripe .campaign-item-margin {
  margin-right:.45rem
 }
 .category-stripe a {
  cursor:pointer;
  width:91px;
  min-height:91px;
  text-decoration:none;
  color:#4a4a4a;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center
 }
 .bnPvke .container-categories-stripe .list-inline-item a small {
  color: white;
}
 .category-stripe a .icon-background {
  background-color:#fff;
  border-radius:100%;
  width:58px;
  height:58px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  box-shadow:0 1px 1px 0 rgba(0,0,0,.1);
  transition:box-shadow .3s
 }
 .category-stripe a .icon-background:hover {
  box-shadow:0 2px 4px 0 rgba(0,0,0,.19),0 2px 4px 0 rgba(0,0,0,.19)
 }
 .category-stripe a small {
  text-align:center;
  margin-top:8px
 }
 @media(max-width:991px) {
  .container-categories-stripe {
   width:100%;
   max-width:none;
   padding-left:0;
   padding-right:0
  }
  .container-categories-stripe .row {
   margin-left:0;
   margin-right:0
  }
  .container-categories-stripe .col-12 {
   padding-left:0!important;
   padding-right:0!important
  }
 }
 .circle {
  width:58px;
  height:58px;
  background-color:#fff;
  border-radius:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  animation:jump 1s ease infinite
 }